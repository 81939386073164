import Cookies from 'js-cookie'
import router from './router'
import store from '@/store'
import request from '@/utils/request'
import { isIOS } from '@/utils/detect'

const localStorageUserInfo = localStorage.getItem('userBKInfo')
const cookieUserInfo = Cookies.get('userBKInfo')

const identifikasi = Number(sessionStorage.getItem('identifikasi'))

router.beforeEach((to, from, next) => {
  // 如果是安卓设备则直接跳转到空白页
  if (!isIOS && to.fullPath !== '/androidPage') {
    next({ path: '/androidPage' })
    return
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const { enhedensdige, a, b, c, d, e, f, g, h, j, k, apitype } = to.query
  if (a || b || c || d || e || f || g || h) {
    const obj = {
      a,
      b,
      c,
      d,
      e,
      f,
      g,
      h,
      j: '',
      k: ''
    }
    if (j) {
      if (j === '1' || j === 'true') {
        obj.j = true
      } else {
        obj.j = false
      }
    }
    if (k) {
      if (k === '1' || k === 'true') {
        obj.k = true
      } else {
        obj.k = false
      }
    }
    sessionStorage.setItem('abcdefgh', JSON.stringify(obj))
  }
  if (apitype) {
    sessionStorage.setItem('apitype', apitype)
  }
  if (enhedensdige) {
    // 存在设备号则代表为h5登录 否则代表由app登录 储存下来作为标识
    sessionStorage.setItem('enhedensdige', enhedensdige)
  }
  if (to.matched.some(record => record.meta.requireAuth)) {
    const userBKInfo = store.state.userBKInfo || {}
    const { userIdBK } = userBKInfo
    const userInfo = cookieUserInfo || localStorageUserInfo
    if (userIdBK) {
      if (!sessionStorage.getItem('enhedensdige')) {
        if (identifikasi) {
          if (identifikasi === 2 && !to.meta.vest) {
            return next('/vest')
          }
          return next()
        }
        return request.post('menghakimi')
          .then(res => {
            sessionStorage.setItem('identifikasi', res.identifikasi)
            if (res.identifikasi === 2 && !to.meta.vest) {
              return next('/vest')
            }
            return next()
          })
          .catch(() => {
            next()
          })
      } else {
        return next()
      }
    }
    if (userInfo) {
      console.log(JSON.parse(userInfo))
      const u = JSON.parse(userInfo)
      store.commit('recordInfo', {
        type: 'userBKInfo',
        value: u
      })
      localStorage.setItem('userBKInfo', userInfo)
      if (!sessionStorage.getItem('enhedensdige')) {
        if (identifikasi) {
          if (identifikasi === 2 && !to.meta.vest) {
            return next('/vest')
          }
          return next()
        }
        return request.post('menghakimi')
          .then(res => {
            sessionStorage.setItem('identifikasi', res.identifikasi)
            if (res.identifikasi === 2 && !to.meta.vest) {
              return next('/vest')
            }
            return next()
          })
          .catch(() => {
            next()
          })
      } else {
        next()
      }
    } else {
      if (sessionStorage.getItem('enhedensdige')) {
        next('/login')
      } else {
        next()
        window.loginoutBuKu()
      }
    }
  } else {
    next()
  }
})
