const Home1 = () => import('@/views/Home1')

export default [
  {
    path: '/',
    redirect: '/home1'
  },
  {
    path: '/home1',
    component: Home1,
    meta: { requireAuth: true }
  }
]
