import axios from 'axios'
import Vue from 'vue'
import qs from 'qs'
import Cookies from 'js-cookie'
import { Toast } from 'vant'
import { baseURL } from '@/config'
import store from '@/store'
import { isIOS } from './detect'

import router from '@/router/index'

Vue.use(Toast)
const showErrorMsg = (msg = 'Internet Error') => {
  Toast(msg)
}

const errorStatus = {
  404: 'Antarmuka Pemrograman Aplikasi tidak tersedia', // 也可能是网络环境问题
  500: 'Sistem sedang sibuk. Silakan coba lagi nanti',
  501: 'Kelainan sistem, silakan login kembali'
}

const errorCode = {
  503: {
    text: 'Masa masuk sudah habis',
    callback () {
      store.commit('recordInfo', {
        type: 'userBKInfo',
        value: {}
      })
      Cookies.remove('userBKInfo')
      localStorage.removeItem('userBKInfo')
      if (sessionStorage.getItem('enhedensdige')) {
        router.push('/login')
      } else {
        window.loginoutBuKu()
      }
    }
  }
}

const instance = axios.create({
  baseURL,
  data: {}
  // timeout: 5000
})

// http请求拦截器
instance.interceptors.request.use(
  config => {
    store.commit('recordInfo', {
      type: 'showLoading',
      value: true
    })
    if (!config.data) {
      config.data = {}
    }
    let userBKInfo = {}
    const { userIdBK, tokenBK, versionBK, deviceNoBK } = store.state.userBKInfo
    // const { userIdBK = 109155, tokenBK = '00ac72a73b262c272873641c64d89eca', versionBK = '1.0.0', deviceNoBK = 1111111111111 } = store.state.userBKInfo
    if (userIdBK) {
      userBKInfo = {
        usdrerID: userIdBK,
        usdrerToken: tokenBK
      }
    }
    const appType = isIOS ? 2 : 1
    const data = {
      apdrpType: appType,
      apdrpVersion: versionBK,
      dedrviceNo: deviceNoBK || sessionStorage.getItem('enhedensdige'),
      loginTerminal: sessionStorage.getItem('enhedensdige') ? 2 : 1,
      ...userBKInfo,
      ...config.data

    }
    // 转为formdata数据格式
    config.data = qs.stringify(data)
    return config
  },
  err => {
    Toast(err.request.response.data.msg)
    console.log('err', err)
    return Promise.reject(err)
  }
)
// http响应拦截器
instance.interceptors.response.use(
  res => {
    store.commit('recordInfo', {
      type: 'showLoading',
      value: false
    })

    if (!res || !res.data) return
    if (res.data.code !== 200) {
      if (res.data.code === 504) {
        return Promise.reject(res.data)
      }
      if (res.data.code === 502) {
        Toast(res.data.msg)
        return Promise.reject(res.data)
      }
      let error = {}
      const o = errorCode[res.data.code]
      if (o) {
        error.msg = res.data.msg || o.text
        o.callback && o.callback.call(null, res.data)
      } else if (res.data.msg) {
        error = res.data
      } else {
        error.msg = 'Sistem sedang sibuk. Silakan coba lagi nanti'
      }
      showErrorMsg(error.msg)
      return Promise.reject(error)
    }
    if (res.data.data === null) {
      const error = { msg: 'Tidak ada data' }
      return Promise.reject(error)
    }
    return Promise.resolve(res.data.data)
  },
  err => {
    store.commit('recordInfo', {
      type: 'showLoading',
      value: false
    })
    console.log('err', err)
    const error = {}
    if (!err.response) {
      error.msg = 'Internet Error'
    } else {
      const status = err.response.status
      if (errorStatus[status]) {
        error.msg = errorStatus[status]
      } else {
        error.msg = 'Internet Error'
      }
    }
    showErrorMsg(error.msg)
    return Promise.reject(error)
  }
)

export default instance
