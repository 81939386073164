// 千分位格式化
function formatNumberWithCommas (number) {
  if (!number) {
    return 0
  }
  // 先将数字转换为字符串
  const numStr = String(number)

  // 使用正则表达式匹配数字的整数部分和小数部分
  const parts = numStr.split('.')
  let integerPart = parts[0]
  const decimalPart = parts.length > 1 ? '.' + parts[1] : ''

  // 对整数部分添加千分位分隔符
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // 拼接整数部分和小数部分
  return integerPart + decimalPart
}
export default formatNumberWithCommas
