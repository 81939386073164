export default [
  {
    path: '/index',
    redirect: '/',
    hidden: true
  },
  // {
  //   path: '/home1',
  //   redirect: '/',
  //   hidden: true
  // }
  {
    path: '/test',
    component: () => import('@/views/test')
  }
]
