<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div v-if="showBackButton" @click="goBack" class="back-btn">
      <img
        :src="
          require(`@/assets/img/${
            backBtnType === '1' ? 'header-' : ''
          }back-btn.png`)
        "
      />
    </div>
    {{ title }}
    <div
      v-if="showCustomerService"
      class="right-block"
      @click="showCustomerServiceModal"
      :style="{ color }"
    >
      Customer Service
    </div>
    <van-dialog
      v-model="visible"
      :showConfirmButton="false"
      close-on-click-overlay
    >
      <template #title>
        <div class="customer-service-title">
          <p>Customer Service</p>
          <div @click="onCloseDialog()" class="close-icon">
            <img src="@/assets/img/close.png" alt="关闭" />
          </div>
        </div>
      </template>
      <div class="customer-service-body">
        <div class="line-block">
          <p class="text">Email Customer Service</p>
          <span class="text">pinjamanindahcs68@gmail.com</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'

export default {
  name: 'BaseHeaderBar',
  mixins: [dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  props: {
    title: {
      type: String,
      default: 'Buku Dana'
    },
    showCustomerService: {
      type: Boolean,
      default: true
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    backBtnType: {
      type: String,
      default: '1'
    },
    color: {
      type: String
    },
    bgColor: {
      default: '#FFF'
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    },
    showCustomerServiceModal () {
      this.visible = true
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 88px;
  line-height: 88px;
  font-size: 30px;
  font-weight: 500;
  color: #4d4d4d;
  text-align: center;
  z-index: 3;
}

.back-btn {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;

  img {
    width: 24px;
    height: 40px;
  }
}

.right-block {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: 600;
  color: #4d4d4d;
  cursor: pointer;
}

.customer-service-title {
  position: relative;

  p {
    .dialog-title;
  }

  .close-icon {
    position: absolute;
    top: 50%;
    right: 32px;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.customer-service-body {
  padding: 32px 25px 54px;

  .line-block {
    display: flex;
    justify-content: space-between;
    height: 88px;
    line-height: 88px;

    .text {
      font-size: 24px;
      color: #333;
    }

    &:nth-child(n + 2) {
      margin-top: 6px;
    }

    &:nth-child(even) {
      background-color: #f6f4f4;
    }
  }
}
</style>
