<template>
  <div>
    <!-- loading显示时禁用遮罩层，阻止点击事件 -->
    <div v-show="isLoading" class="loading-overlay"></div>

    <!-- 自定义loading图标 -->
    <div v-show="isLoading" class="loading-spinner">
      <div class="logding_box">
        <img src="@/assets/img/loding.gif" alt="Loading" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* 半透明黑色遮罩层 */
  z-index: 9999; /* 确保遮罩层位于最上层 */
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 居中显示 */
  z-index: 9999; /* 确保loading图标位于遮罩层之上 */
}

.loading-spinner img {
  width: 100px; /* 自定义图片的宽度 */
  height: auto; /* 高度自适应 */
}
.logding_box {
  position: relative;
  background: #000;
  opacity: 0.8;
  width: 230px;
  height: 230px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 80px;
    width: 80px;
  }
}
</style>
